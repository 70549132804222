import React from 'react'
import { Toast } from 'react-bootstrap'
import {
  Images, Text
} from '../components/common'

const NotificationToaster = (props) => {
  const { showNotification, closeNotification, title = 'Toaster', bodyMessage = '', } = props
  return (
    <>
      <div className="bootstrap" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Toast onClose={closeNotification} show={showNotification} delay={5000} autohide style={{ zIndex: '9999', position: 'fixed' }}>
            <Toast.Header className="pt-2 mt-4 pl-4 pr-4">
              <Text.H3 >{title}</Text.H3>
            </Toast.Header>
            <Toast.Body className="p-4 ">
              <Text.MainText>{bodyMessage}</Text.MainText>
            </Toast.Body>
          </Toast>
      </div>
    </>
  )
}

export default NotificationToaster