import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import _ from 'lodash'
import util from '../utils/utils'
import {
  Flex,
  Images,
  ButtonGreen,
  GreenBarSection, HeaderBanner,
  Text, WhiteSection, Vis
} from '../components/common'

// custom component
const CloseButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  height: 30px;
  width: 30px;
  text-align: center;
  font-weight: 700;
  color: #006778;
  display: block;
`


const DetailModal = props => {

  const { isOpen, consentDetail, FnCloseDetail } = props
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [consent, setConsent] = useState({})
  const [openAccordion, setOpenAccordion] = useState(false)

  useEffect(() => {
    setShow(isOpen)
    if (isOpen) {
      console.log(' on load', consentDetail)
      setConsent(consentDetail)
    } else {
      setConsent({})
    }
  }, [isOpen])

  let untilTime = ''
  let statusText = ''
  if (Object.keys(consent).length > 0) {
    // TODO change requested to consented
    untilTime = util.jsonGet(consent, 'auth.timeline.consented') !== null && moment(util.jsonGet(consent, 'auth.timeline.consented'))
    if (util.jsonGet(consent, 'dataUsage.periodOfUse.days')) {
      untilTime.add(util.jsonGet(consent, 'dataUsage.periodOfUse.days'), 'days')
    }
    if (util.jsonGet(consent, 'dataUsage.periodOfUse.months')) {
      untilTime.add(util.jsonGet(consent, 'dataUsage.periodOfUse.months'), 'months')
    }

    if (util.jsonGet(consent, 'auth.status.current') === false) {
      statusText = statusText + 'This sharing has expired. We are no longer collecting this data.'
      if (consent.dataUsage.status !== 'EXPIRED') {
        statusText = statusText + ` We may use the collected data until ${untilTime.format('DD MMM YYYY')}`
      } else {
        statusText = statusText + ' We are no longer using this data.'
      }
    } else {
      statusText = 'We are actively collecting this data.'
    }
  }



  return (
    <>
      <div className="bootstrap" style={{ position: 'relative' }}>
        {
          isOpen &&
          <div className="modal show" tabIndex="-1" style={{ overflow: 'auto', display: 'block', backgroundColor: 'rgba(33,37,41,0.3)' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="container p-4">
                    <Flex flex="row">
                      <Flex flex="col nogrow pad-lg" className="d-flex vcenter hcenter pt-1">
                        <img
                          
                          src={util.jsonGet(consent, 'dataholder.logoUri')}
                          width="60"
                          className="d-inline-block align-center mb-0 object-fit_contain"

                        />
                      </Flex>
                      <Flex flex="col grow">
                        <h3>{util.jsonGet(consent, 'dataholder.brandName')}</h3>
                        <p>
                          <b className="bold-text">For: </b>{util.jsonGet(consent, 'softwareProduct.CxTexts.PurposeCX')}<br />
                          <b className="bold-text">Data Collection Date: </b>{util.jsonGet(consent, 'auth.timeline.consented') !== null && moment(util.jsonGet(consent, 'auth.timeline.consented')).format('DD MMM YYYY')}<br />
                          <b className="bold-text">Data Use Expires: </b>{untilTime !== '' && untilTime.format('DD MMM YYYY')}
                        </p>
                      </Flex>
                    </Flex>
                    <Flex flex="row">
                      <p>{statusText}</p>
                    </Flex>
                    <CloseButton>
                      <Images.CloseButtonIcon onClick={() => FnCloseDetail()} className="cursor-pointer" />
                    </CloseButton>
                  </div>
                </div>
                <div className="modal-body">
                  <h4><b>About your data</b></h4>
                  <p><b>What was collected</b></p>
                  <div className="pb-2">
                    <p><b>Transaction Details</b></p>
                    <div className="border border-dark px-2 py-2" id="collapse-transactions">
                      <p><b>Why we need it</b></p>
                      <p>{util.jsonGet(consent, 'softwareProduct.CxTexts.AboutDataCollection.Clusters.TRANSACTION_DETAILS.Why')}</p>
                      <p><b>What we've collected</b></p>
                      <ul>{util.jsonGet(consent, 'softwareProduct.CxTexts.AboutDataCollection.Clusters.TRANSACTION_DETAILS.Permissions') && util.jsonGet(consent, 'softwareProduct.CxTexts.AboutDataCollection.Clusters.TRANSACTION_DETAILS.Permissions').map((x, i) => <li key={i}>{x}</li>)}</ul>
                      {util.jsonGet(consent, 'dataUsage.transactions') !== null && (
                        <>
                          <p><b>Historical data we've collected</b></p>
                          <p>We have collected transaction data that dates back to {moment(util.jsonGet(consent, 'dataCollection.Clusters.TRANSACTION_DETAILS.historicalDataCollection.earliestHistorical')).format('DD MMM YYYY')}</p>
                        </>
                      )}
                      <p><b>When we've collected your data</b></p>
                      <p>We collected your data once only on {util.jsonGet(consent, 'dataCollection.Clusters.TRANSACTION_DETAILS.whenCollected.onceOnly') && moment(util.jsonGet(consent, 'dataUsage.transactions.whenFirstCcollectedDate')).format('DD MMM YYYY')}</p>
                      <p><b>What happened to your data sharing</b></p>
                      <p>The data you have shared has been deleted. <a href="">Learn more.</a></p>
                    </div>
                  </div>
                  <div>
                    <p><b>What happened to your shared data</b></p>
                    <p>{util.jsonGet(consent, 'softwareProduct.CxTexts.WhatHappened')}</p>
                    <p><b>How your data was used</b></p>
                    <p>{util.jsonGet(consent, 'softwareProduct.CxTexts.HowUsed')}</p>
                    <p><b>Sharing with third parties</b></p>
                    <p>Supporting third parties no longer have access to your data. They were bound by the same terms of this agreement. <a href="">Learn more.</a></p>
                    <p><b>When you gave consent</b></p>
                    <p>{util.jsonGet(consent, 'auth.timeline.consented') !== null && moment(util.jsonGet(consent, 'auth.timeline.consented')).format('DD MMM YYYY')}</p>
                    <p><b>When your consent expired</b></p>
                    <p>Consent for data collection expired:  {util.jsonGet(consent, 'auth.timeline.sharingEnd') !== null && moment(util.jsonGet(consent, 'auth.timeline.sharingEnd')).format('DD MMM YYYY')} <br></br>
                      consent for data use expire(d)(s): {untilTime !== '' && untilTime.format('DD MMM YYYY')}
                    </p>
                    <p><b>How often we collected your data</b></p>
                    <p>We collected your data once only</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

    </>
  )
}

export default DetailModal