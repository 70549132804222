import React from 'react'
import Layout from '../../components/layout'
import styled from 'styled-components'
import {
  Flex,
  WhiteSection,
  LightBlueSection,
  WhiteBox,
  HeaderBanner,
  GreenBarSection,
  ButtonGreen,
  Text,
  ButtonLinkOutline,
  FooterBanner,
  BulletPoint,
  Images,
  Vis,
  MOBILE_ONLY_CSS_WRAPPER,
  TABLET_ONLY_CSS_WRAPPER,
  DESKTOP_ONLY_CSS_WRAPPER,
  LightBlueSectionNoMargin
} from '../../components/common'
import { navTo } from '../../utils/navigation'
import util from '../../utils/utils'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import api from '../../utils/api'
import moment from 'moment-timezone'
import DetailModal from '../../components/DetailModal'
import NoticationToaster from '../../components/NotificationToaster'
import WestpackLogo from '../../images/Westpack.svg'
import Vector from '../../images/Vector.svg'
import ScopeVector from '../../images/ScopeVector.svg'
import HoverScopeVector from '../../images/HoverScopeVector.svg'
import FilterVector from '../../images/FilterVector.svg'
import WhiteFilterVector from '../../images/WhiteFilterVector.svg'
import SearchVector from '../../images/SearchVector.svg'
import ErrorIcon from '../../images/Error_24.svg'
import InactiveIcon from '../../images/Inactive.svg'
import CloseDarkBlue from '../../images/Close_DarkBlue_30.svg'
import TickBoxSVG from '../../images/Tickbox.svg'
import GlobalStoreContext from '../../components/GlobalContext'
import _ from 'lodash'
import 'rsuite/dist/styles/rsuite-default.css'
import { Popover, Whisper, Table, Dropdown, IconButton, Icon, ButtonGroup, Button, Checkbox, CheckboxGroup, Divider, Message } from 'rsuite'

const { ColumnGroup, Column, HeaderCell, Cell, Pagination } = Table

const ConsentTable = styled(Table)`
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover, .rs-table-hover .rs-table-row:hover .rs-table-cell-group, .rs-table-hover .rs-table-row:hover .rs-table-cell {
    background: #f8f9fa !important;
  }
`

const TableHeader = styled(HeaderCell)`
  color: #000000;
  font-weight: bold;
`

const TableCell = styled(Cell)`
  color: #000000;
  font-weight: ${(props) => props.weight || 'normal'};
`

const Scopes = styled(Flex)`
  width: 142px;
  font-size: 14px;
  color: #373737;
  gap: 5px;

  .hover-inline {
    display: none;
  }

  :hover {
    background: #000000;
    color: #ffffff;
    border-radius: 4px;

    .hover-inline {
      display: inline;
    }

    .hover-hidden {
      display: none
    }
  }
`

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;  
  width: 23px;
  height: 23px;
  border: 1px solid #333333;
  border-radius: 5px;  
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    border: 1px solid #016E80;

    &:after {
    content: '';
    background-image: url(${TickBoxSVG});
    width: 20px;
    height: 20px;
    display: inline-block;
    top: -2px;
    left: -1px;
    position: absolute;
  }

    & + label {
      opacity: 1;
    }
  }
`
const CheckboxLabel = styled.label`  
  opacity: 0.5;
  cursor: pointer;
`

const FilterTextBox = styled(Flex)`
padding: 5px 0px;
:hover {
  background: #D6D6D6;
  border-radius: 4px;
  color: #000000;
}
`

const RadioButton = styled.input.attrs({ type: 'radio' })`  
  display: none;
    
  &:checked + label {
    background: #ffffff;
    color: rgba(0, 66, 77, 1);
    font-weight: bold;
    opacity: 1;
  }
  &:not(:checked):hover + label{
    color: #373737;
    opacity: 0.9;
  }
`

const RadioLabel = styled.label`
  padding: 5px 1.5rem;
  color: rgba(0, 66, 77, 1);
  font-weight: bold;
  opacity: 0.6;
  display: inline-block;
  border-radius: 4px;  
  cursor: pointer;
`

const ActionButton = styled.button`
  width: 100%;
  color: rgba(51,51,51,1);
  max-width: ${props => props.maxwidth || '200px'};
  font-size: 12px;
  font-weight: 800;
  padding: 10px 0;
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 4px;
  margin-right: ${props => props.margin || 0};

  &:hover, &:focus {
    color: rgba(51, 51, 51, 0.6);
    border: 1px solid rgba(51, 51, 51, 0.5);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.12);
  }

  &:disabled {
    color: rgba(51,51,51,0.5);
    border: 1px solid rgba(51,51,51,0.5);
  }

`

const ScopesObj = styled(Flex)`
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid black;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

const RevokeCloseButton = styled(Flex)`
:hover{
  opacity: 0.6;
}
`

const ButtonDelay = () => {
  setTimeout(() => navTo.howitworks_2(), 300)
}

const clustersScopeMapping = {
  "bank:accounts.basic:read": 'ACCOUNT_BALANCE_AND_DETAILS',      //ACCOUNT_NAME_TYPE_AND_BALANCE subcluster
  "bank:accounts.detail:read": 'ACCOUNT_BALANCE_AND_DETAILS',
  "bank:transactions:read": 'TRANSACTION_DETAILS',
  "bank:regular_payments:read": 'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS',
  "common:customer.basic:read": 'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS',    //NAME_AND_OCCUPATION subcluster
  "common:customer.detail:read": 'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS',
  "bank:payees:read": 'SAVED_PAYEES',
  "profile": 'PROFILE'
}

const clustersToNameMapping = {
  'ACCOUNT_BALANCE_AND_DETAILS': 'Account balance and details',
  'TRANSACTION_DETAILS': 'Transaction details',
  'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS': 'Direct debits and scheduled payments',
  'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS': 'Name and occupation and contact details',
  'SAVED_PAYEES': 'Saved payees',
  "PROFILE": 'Profile'
}

const headingText = 'Consent Dashboard'


const StyledButton = styled.button`
  background: transparent;
  borderRadius: '4px';
  :hover {
    background: #D6D6D6;
  }
`

// Popover for Data holder & Scopes filter
const FilterPopover = ({ dataHolder = null, scopes = null, title, filterTitle, onSelectFn, selectedState }) => {
  const [isClicked, setIsClicked] = React.useState(false)

  const onSelect = (value) => {

    const inx = selectedState.indexOf(value)
    let working = _.clone(selectedState)
    if (inx !== -1) {
      working.splice(inx, 1)
    } else {
      working.push(value)
    }
    onSelectFn([...working])
  }

  const selectAll = () => {
    let all = []
    if (dataHolder) {
      all = dataHolder.map((x) => x.holderName)
    } else {
      all = scopes.map((x) => x)
    }
    onSelectFn(all)
  }

  return (
    <Whisper onEnter={() => setIsClicked(true)} onExit={() => setIsClicked(false)} key={title} controlId='scopes' trigger='click' enterable placement='bottomEnd' speaker={
      <Popover full placement='bottomEnd' style={{ minWidth: '340px', boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12), 0px 3px 24px rgba(0, 0, 0, 0.06), 0px 8px 10px rgba(0, 0, 0, 0.18)' }}>
        <Flex flex='pad-md col' style={{ paddingBottom: '0px' }}>
          <Text.H5 style={{ paddingBottom: '2px' }}>{filterTitle}</Text.H5>

          <Flex flex='vcenter col'>
            {/*  Render Scope section */}
            {(scopes && scopes.length > 0) &&
              <Flex flex='col' style={{ maxHeight: '430px', overflow: 'auto' }}>
                {scopes.filter(x => x !== 'openid').map((x, inx) => <Flex key={inx} flex='vcenter' style={{ padding: '10px 0' }}>
                  <CheckBox id={x} onChange={() => onSelect(x)} checked={selectedState.includes(x)} type="checkbox" name="scopes" style={{ zIndex: 20, width: '20px', height: '20px' }} />
                  <CheckboxLabel htmlFor={x}>
                    <Flex flex='vcenter'>
                      <Text.Karbon16 style={{ color: '#333333', paddingLeft: '10px' }}>{x}</Text.Karbon16>
                    </Flex>
                  </CheckboxLabel>
                </Flex>)}
              </Flex>
            }
            {/*  Render Data holder section */}
            {(dataHolder && dataHolder.length > 0) &&
              <Flex flex='col' style={{ maxHeight: '430px', overflow: 'auto' }}>
                {dataHolder.map((d, inx) => <Flex key={inx} flex='vcenter' style={{ padding: '10px 0' }}>
                  <CheckBox id={d.holderName} onChange={() => onSelect(d.holderName)} checked={selectedState.includes(d.holderName)} type="checkbox" name="holder" style={{ zIndex: 20, width: '20px', height: '20px' }} />
                  <CheckboxLabel htmlFor={d.holderName}>
                    <Flex flex='vcenter'>
                      <img src={d.dataholder.logoUri} width='22' height='22' alt="Westpac Logo" style={{ margin: '0 10px', objectFit: 'contain' }} />
                      <Text.Karbon16 style={{ color: '#333333' }}>{d.holderName}</Text.Karbon16>
                    </Flex>
                  </CheckboxLabel>
                </Flex>
                )}
              </Flex>
            }

          </Flex>

        </Flex>
        <Divider style={{ margin: '0' }} />
        <Flex flex='pad-md between'>
          <button onClick={selectAll} style={{ background: 'transparent', color: '#373737' }}><Text.Karbon16 style={{ fontWeight: 'bold' }}>Select all</Text.Karbon16></button>
          <button onClick={() => onSelectFn([])} style={{ background: 'transparent', color: '#373737' }}><Text.Karbon16 style={{ fontWeight: 'bold' }}>Clear all</Text.Karbon16></button>
        </Flex>
      </Popover>
    }>
      <StyledButton style={{ background: isClicked ? '#333333' : null }}>
        <Flex flex='vcenter hcenter pad-sm' style={{ gap: '1rem' }}>
          <Flex flex='vcenter'>
            <Text.Karbon16 style={{ color: isClicked ? '#FFFFFF' : (selectedState.length > 0 ? '#000000' : 'rgba(51, 51, 51, 0.75)'), fontWeight: selectedState.length > 0 ? 'bold' : 'normal' }}>{title}</Text.Karbon16>
          </Flex>
          {isClicked ? <img src={WhiteFilterVector} alt="is clicked" /> : <img src={FilterVector} alt="not clicked" />}

          {/* <img src={FilterVector} alt="Filter vector svg" className='hover-inline' /> */}

        </Flex>
      </StyledButton>
    </Whisper>
  )
}

// react component 
const CustomCollectDataWithMutation = ({ consentId }) => {

  const collectDataMutation = useMutation(async (consentId) => {
    const { data } = await api.collectData(consentId)
    return data
  }, {
    onSuccess: (data) => {
      const { requestedScopes, internalDataHolderBrandId } = data
      // route to page 9
      navTo.collectingyourdata_9({ internalRequestedScopes: requestedScopes, internalDataHolderBrandId })
    },
    onError: (err) => {
      console.log("Collect data request failed", err)
      // update error message with
    }
  })

  return (<ActionButton onClick={() => collectDataMutation.mutate(consentId)} maxwidth='140px'>{collectDataMutation.isLoading ?
    <span className="bootstrap">
      <div className="spinner-border spinner-border-sm text-dark" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </span> :
    'Collect'}
  </ActionButton>)
}

const CustomAmendDataWithMutation = ({ rowData }) => {
  const { auth: { arrangementId = null }, dataholder = {}, scopes, consentId } = rowData
  const { setAmendConsent } = React.useContext(GlobalStoreContext)
  const onClick = () => {
    setAmendConsent({
      bank: {
        dataHolderBrandId: dataholder.dataHolderBrandId,
        logoUri: dataholder.logoUri
      },
      dataClusters: scopes,
      arrangementId,
      existingConsentId: consentId
    })
    navTo.configureyourconsent_2()
  }
  return (<ActionButton onClick={() => onClick()} disabled={arrangementId === null} maxwidth='140px'>{
    'Amend'}
  </ActionButton>)
}

// react component for revoke
const CustomRevokeWithMutation = ({ consentId, callBackOnSuccessFn, callBackOnFailureFn }) => {
  const queryClient = useQueryClient()
  const revokeConsent = useMutation(async (consentId) => {
    const { data } = await api.revokeConsent(consentId)
    return data
  }, {
    onSuccess: (data) => {
      // TODO show toaster
      console.log("Revoke consent success", data)
      queryClient.invalidateQueries('consentsList')
      callBackOnSuccessFn(true)

    },
    onError: (err) => {
      console.log("Unable to revoke consent", err)
      callBackOnFailureFn("Unable to revoke consent, please try again.")
    }
  })

  const revokeRef = React.useRef()
  const close = () => revokeRef.current.close()

  const revokeSpeaker = (
    <Popover style={{ padding: '0', maxWidth: '462px', boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12), 0px 3px 24px rgba(0, 0, 0, 0.06), 0px 8px 10px rgba(0, 0, 0, 0.18)' }}>
      <Flex flex='col'>
        <Flex flex='pad-md'>
          <Text.Karbon16 style={{ fontWeight: '800' }}>Revoke consent?</Text.Karbon16>
        </Flex>
        <div style={{ background: 'rgba(245, 250, 255, 1)' }}>
          <Flex flex='vstart pad-md' style={{ gap: '10px' }}>
            <img src={ErrorIcon} alt="" />
            <Text.Karbon14 style={{ textAlign: 'left', color: '#333333' }}>If you revoke consent, we will not have access to this data from [Data holder], and you cannot use it for further testing and debugging.</Text.Karbon14>
          </Flex>
          <Flex flex='vcenter pad-md' style={{ gap: '10px' }}>
            <img src={InactiveIcon} alt="" />
            <Text.Karbon14 style={{ textAlign: 'left', color: '#333333' }}>We will delete all data associated with this consent.</Text.Karbon14>
          </Flex>
        </div>
        <Flex flex='pad-md'>
          <Text.Karbon16 style={{ color: '#333333', fontWeight: '800' }}>Are you sure you want to revoke this consent?</Text.Karbon16>
        </Flex>
        <Flex flex='hend pad-md' style={{ gap: '10px' }}>
          <ActionButton onClick={close}>
            Cancel
            {/* <Text.Karbon14 style={{ fontWeight: '800', color: '#333333' }}>Cancel</Text.Karbon14> */}
          </ActionButton>
          <ActionButton onClick={() => {
            revokeConsent.mutate(consentId)
            close()
          }}>
            Yes, revoke
          </ActionButton>
        </Flex>
      </Flex>
    </Popover>
  )


  return (
    <Whisper trigger='click' enterable placement='topEnd' ref={revokeRef} speaker={revokeSpeaker}>
      <ActionButton maxwidth='140px'>{
        revokeConsent.isLoading ?
          <span className="bootstrap">
            <div className="spinner-border spinner-border-sm text-dark" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </span> :
          'Revoke'
      }
      </ActionButton>
    </Whisper>
  )
}


const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalData, setModalData] = React.useState(null)
  const [sortColumn, setSortColumn] = React.useState('expiry')
  const [sortType, setSortType] = React.useState('desc')
  const [isSorting, setIsSorting] = React.useState(false)
  const [openNotification, setOpenNotification] = React.useState(false)
  const [notificationMessage, setNotificationMessage] = React.useState(null)
  const { filterIsActive,
    filterSearchString,
    filterSelectDH,
    filterSelectScopes,
    setFilterIsActive,
    setFilterSearchString,
    setFilterSelectDH,
    setFilterSelectScopes } = React.useContext(GlobalStoreContext)

  const [localFilterString, setLocalFilterString] = React.useState(filterSearchString)
  // limit 8 data
  const [limit, setLimit] = React.useState(8)
  // current page
  const [page, setPage] = React.useState(1)
  // Set background
  const [displayConsentList, setDisplayConsentList] = React.useState([])
  // dataHolder dropdown
  const [dhDropdown, setDhDropDown] = React.useState([])
  // scopes dropdown
  const [scopesDropDown, setScopesDropDown] = React.useState([])
  // revoke message
  const [openRevokeBanner, setOpenRevokeBanner] = React.useState(false)

  const queryClient = useQueryClient()
  let { isLoading: userIsLoading, data: userData } = useQuery('users', async () => {
    return new Promise((resolve, reject) => {
      api.getUserDetail().then((returnData) => resolve(returnData.data)).catch(err => reject(err))
    })
  }, {
    retry: 1,
    onError: (error) => {
      const { response } = error
      if (!response || response.status === 403) {
        // not authorized route back to intro page
        navTo.introduction_1()
      }
    },
    refetchOnWindowFocus: false
  })

  let { isLoading, data: consentList = [] } = useQuery('consentsList', async () => {
    return new Promise((resolve, reject) => {
      api.getUserConsentsList().then((returnData) => {
        const workingSet = returnData.data.map(con => {
          const consentObject = {
            id: util.jsonGet(con, 'consentId'),
            holderName: util.jsonGet(con, 'dataholder.brandName'),
            duration: util.jsonGet(con, 'auth.timeline.sharingDuration'),
            consentDate: util.jsonGet(con, 'auth.timeline.consented'),
            scopes: util.jsonGet(con, 'auth.scopes.confirmed') ? util.jsonGet(con, 'auth.scopes.confirmed') : [], // need to remove openid
            status: util.jsonGet(con, 'auth.status.lifecycle'),
            hasData: util.jsonGet(con, 'hasData'),
            count: util.jsonGet(con, 'count') ? util.jsonGet(con, 'count') : 0,
            expiry: util.jsonGet(con, 'auth.timeline.sharingDuration') > 0 ? util.jsonGet(con, 'auth.timeline.sharingEnd') : util.jsonGet(con, 'auth.timeline.consented'),
            ...con
          }
          return consentObject
        })
        resolve(workingSet)
      }).catch((error) => {
        reject(error)
      })
    })
  }, {
    retry: 1,
    onError: (error) => {
      const { response } = error
      console.log("error", error)
      if (!response || response.status === 403) {
        // not authorized route back to intro page
        navTo.introduction_1()
      }
    },
    refetchOnWindowFocus: true
  })

  const DataHoldersCell = ({ rowData, dataKey, ...props }) => {
    const { dataholder, holderName = '' } = rowData
    return (
      <Cell {...props}>
        <Flex flex='vcenter'>
          <img src={dataholder.logoUri} width='30' height="30" alt="dataholder logo" style={{ objectFit: 'contain' }} />
          <Text.Karbon16 style={{ marginLeft: '10px', color: '#000000', fontWeight: '800' }}>{holderName}</Text.Karbon16>
        </Flex>
      </Cell>
    )
  }


  let tableBody; // this is use to refer where the table is on the screen

  const ScopesCell = ({ rowData, dataKey, ...props }) => {
    const { scopes = {} } = rowData
    let clusterPills = {}
    Object.keys(clustersToNameMapping).forEach(c => {
      clusterPills[c] = []
    })
    scopes.forEach(s => {
      if (clustersScopeMapping[s]) {
        clusterPills[clustersScopeMapping[s]].push(s)
      }
    })

    const speaker = (
      <Popover full style={{ minWidth: '600px', minHeight: '320px', boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12), 0px 3px 24px rgba(0, 0, 0, 0.06), 0px 8px 10px rgba(0, 0, 0, 0.18)' }}>
        <Flex flex='col' style={{ height: '320px' }}>
          {/* header */}
          <Flex flex='nogrow between' style={{ padding: '20px 16px 10px 16px' }}>
            <div style={{ fontSize: '16px', fontWeight: '800', color: '#373737' }}>Data cluster</div>
            <div style={{ fontSize: '16px', fontWeight: '800', color: '#373737' }}>Auth Scope ID</div>
          </Flex>

          {/* content */}
          <Flex flex='between' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', padding: '0 16px' }}>
            <Flex flex='col' style={{}}>
              {/* start  */}
              {Object.keys(clusterPills).map((key, inx) => (
                <Flex key={inx} flex='between vcenter' style={{ height: '34px' }}>
                  <Flex flex='nogrow'>

                    <Text.Karbon14 style={{ fontWeight: '600', color: '#333333' }}>
                      {clustersToNameMapping[key]}
                    </Text.Karbon14>

                  </Flex>
                  <Flex flex='hend'>
                    {/* Pills goes here */}
                    {clusterPills[key].map((p, inx) => (
                      <div key={inx}>
                        <Flex>
                          <div style={{ padding: '6px', background: '#f8f9fa', borderRadius: '4px', fontSize: '12px', fontWeight: '600', color: '#000000' }}>
                            <Text.Karbon14 style={{ fontFamily: 'monospace', fontSize: '11px', color: '#ab0000' }}>{p}</Text.Karbon14>
                          </div>
                        </Flex>
                      </div>
                    ))}
                    {clusterPills[key].length === 0 &&
                      <div>
                        <Flex>
                          <div style={{ padding: '6px', width: '26px', textAlign: 'center', background: '#f8f9fa', borderRadius: '4px', fontSize: '12px', fontWeight: '600', color: '#000000' }}>
                            <Text.Karbon14 style={{ fontFamily: 'monospace', fontSize: '11px', color: '#ab0000' }}>-</Text.Karbon14>
                          </div>
                        </Flex>
                      </div>
                    }
                  </Flex>
                  {/* When no details */}
                  {/* <div>
                  <Flex>
                    <div style={{ padding: '6px', background: '#f8f9fa', borderRadius: '4px', fontSize: '12px', fontWeight: '600', color: '#000000' }}>
                      <p style={{ fontWeight: 'normal', padding: '0 10px' }}>-</p>
                    </div>
                  </Flex>
                </div> */}
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Flex flex='nogrow hend' style={{ padding: '11px 16px' }} >
            <div onClick={() => navTo.consentdetail(rowData)} style={{ textDecoration: 'none', fontSize: '16px', fontWeight: '800', color: '#373737', cursor: 'pointer' }}>View details</div>
          </Flex>
        </Flex>
      </Popover>
    )

    return (
      <Cell {...props}>
        <Whisper trigger='hover' enterable placement="auto" speaker={speaker} container={() => {
          return tableBody;
        }}>
          <Scopes flex='cursor between pad-sm'>
            <img src={ScopeVector} alt="" className='hover-hidden' />
            {/* When hover  */}
            <img src={HoverScopeVector} alt="" className="hover-inline" />
            Scope summary
          </Scopes>
          {/* <span>{rowData.scopes.length}</span> */}
        </Whisper>
      </Cell>
    )
  }

  // custom whisper for action 
  const CustomWhisper = (props) => {
    const trigger = React.useRef(null)
    const handleSelectMenu = (eventKey, event) => {
      // console.log('handleSelectMenu', eventKey, props.data)
      // if (eventKey === 'download') {
      //   // trigger download of PDF
      //   console.log("Download")
      //   if (util.isMobile()) {
      //     window.open('/api/pdf?device=mobile', '_blank');
      //   } else {
      //     window.open(`/api/pdf/download/${props.data.id}`, '_blank');
      //   }
      // }
      // if (eventKey === 'collectData') {
      //   // route to the collect data page 9 screen to re-trigger of fetching data
      //   collectDataMutation.mutate(props.data.id)
      // }
      // if (eventKey === 'revoke') {
      //   // call ADR to revoke consent
      //   // refresh the list once its success
      //   console.log("Revoke consent")
      //   revokeConsent.mutate(props.data.id)
      // }
      trigger.current.hide()
    }

    return (
      <Whisper
        placement="bottom"
        trigger={props.trigger}
        enterable={props.enterable}
        triggerRef={trigger}
        container={() => {
          return tableBody;
        }}
        speaker={<MenuPopover data={props.data} onSelect={handleSelectMenu} />}
      >
        {props.children}
      </Whisper>
    )
  }

  // Popover menu for Action cell
  const MenuPopover = ({ onSelect, ...rest }) => {
    const { data } = rest
    const hasData = data.hasData && data.hasData === true
    const canCollectMore = !(data.status === 'EXPIRED' || data.status === 'REVOKED')
    const canRevoke = !(data.status === 'EXPIRED' || data.status === 'REVOKED')
    return (
      <Popover {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
          <div style={{ padding: '0 14px', fontSize: '16px', fontWeight: '400', textDecoration: 'none', color: '#31708f' }}>Detail view</div>
          {/* {hasData && <Dropdown.Item eventKey={'download'}>See data</Dropdown.Item>}
          {canCollectMore && <Dropdown.Item eventKey={'collectData'}>Collect data</Dropdown.Item>}
          {canRevoke && <Dropdown.Item eventKey={'revoke'}>Revoke</Dropdown.Item>}
          {!hasData && !canCollectMore && !canRevoke && <Dropdown.Item>No action</Dropdown.Item>} */}
        </Dropdown.Menu>
      </Popover>
    )
  }

  // custom notification when error revoking
  const onRevokeFailureFn = (message) => {
    setOpenNotification(true)
    setNotificationMessage(message)
  }

  const ActionCell = ({ rowData, dataKey, ...props }) => {

    return (
      <Cell {...props}>

        {filterIsActive === 'active'
          ?
          <Flex flex='between vcenter' style={{ gap: '5px' }}>
            <CustomRevokeWithMutation consentId={rowData.consentId} callBackOnSuccessFn={(val) => setOpenRevokeBanner(val)} callBackOnFailureFn={(msg) => onRevokeFailureFn(msg)} />
            <CustomAmendDataWithMutation rowData={rowData} />
            <CustomCollectDataWithMutation consentId={rowData.consentId} />
            <CustomWhisper data={rowData} trigger='hover'>
              <div onClick={() => navTo.consentdetail(rowData)} style={{ background: 'transparent', cursor: 'pointer' }}>
                <img src={Vector} alt="" />
              </div>
            </CustomWhisper>
          </Flex>
          :
          <Flex flex='between vcenter' style={{ gap: '5px' }}>
            <div style={{ minHeight: '39.1px', height: '100%', width: '100%', background: '#F8F9FA' }}></div>
            <CustomWhisper data={rowData} trigger='hover'>
              <div onClick={() => navTo.consentdetail(rowData)} style={{ background: 'transparent', cursor: 'pointer' }}>
                <img src={Vector} alt="" />
              </div>
            </CustomWhisper>
          </Flex>
        }

        {/* <span>
          <a onClick={handleAction}> View consent details </a> |{' '}
          <CustomWhisper data={rowData}>
            <IconButton appearance="subtle" icon={<Icon icon="more" />} />
          </CustomWhisper>
        </span> */}
      </Cell>
    )
  }

  const getData = () => {
    if (sortColumn && sortType) {
      return displayConsentList.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]
        if (sortColumn === 'duration') {
          if (sortType === 'asc') {
            return parseInt(x) > parseInt(y) ? 1 : parseInt(x) < parseInt(y) ? -1 : 0
          } else {
            return parseInt(x) < parseInt(y) ? 1 : parseInt(x) > parseInt(y) ? -1 : 0
          }
        } else if (sortColumn === 'scopes') {
          if (sortType === 'asc') {
            return x.length > y.length ? 1 : x.length < y.length ? -1 : 0
          } else {
            return x.length < y.length ? 1 : x.length > y.length ? -1 : 0
          }
        } else if (sortColumn === 'holderName') {
          if (typeof x === 'string') {
            x = x.charCodeAt()
          }
          if (typeof y === 'string') {
            y = y.charCodeAt()
          }
          if (sortType === 'asc') {
            return x - y
          } else {
            return y - x
          }
        } else if (sortColumn === 'expiry') {
          if (sortType === 'asc') {
            return moment(x).isBefore(moment(y)) ? 1 : moment(y).isBefore(moment(x)) ? -1 : 0
          } else {
            return moment(y).isBefore(moment(x)) ? 1 : moment(x).isBefore(moment(y)) ? -1 : 0
          }
        }
      }).filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      })
    }
    const startT = limit * (page - 1)
    return displayConsentList.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    })
  }


  const handleSortColumn = (sortColumn, sortType) => {
    // this.setState({
    //   loading: true
    // });
    setIsSorting(true)
    setTimeout(() => {
      setSortColumn(sortColumn)
      setSortType(sortType)
      setIsSorting(false)
    }, 500);
  }

  const handleChangeActive = (event) => {
    // reset scope and dataholder selection drop
    setFilterSelectDH([])
    setFilterSelectScopes([])
    setFilterIsActive(event.target.value)
  }

  const debouncedSearch = _.debounce(updateSearchString, 250);

  const debounceHandleSearchTextChange = (e) => {
    const value = e.target.value
    setLocalFilterString(value) // set this local value all the time to improve screen rendering
    debouncedSearch(value)
  }

  function updateSearchString(e) {
    setFilterSearchString(e)
  }

  // handle on change outside of table, such as active, inactive, filter, data holder and scope selection
  React.useEffect(() => {
    let tmp = displayConsentList
    if (consentList.length > 0) {
      setPage(1)
      if (filterIsActive === 'active') {
        tmp = consentList.filter(x => x.status !== 'EXPIRED' && x.status !== 'REVOKED')

      } else {
        tmp = consentList.filter(x => x.status === 'EXPIRED' || x.status === 'REVOKED')
      }
      if (filterSearchString && filterSearchString.length > 0) {
        tmp = tmp.filter(x => {
          return x.holderName.toLowerCase().includes(filterSearchString.toLowerCase()) ||
            moment(x.expiry).format('DD/MM/YYYY hh:mm a').includes(filterSearchString.toLowerCase()) ||
            x.duration.toString().includes(filterSearchString.toLowerCase())
        })
      }
      if (filterSelectDH.length > 0) {
        tmp = tmp.filter(x => filterSelectDH.includes(x.holderName))
      }
      if (filterSelectScopes.length > 0) {
        tmp = tmp.filter(x => x.scopes.some(s => filterSelectScopes.includes(s)))
      }
      setDisplayConsentList(tmp)
    }

  }, [consentList, filterIsActive, filterSearchString, filterSelectDH, filterSelectScopes])

  // This setup the drop down list for Scopes and Data holders
  React.useEffect(() => {
    if (consentList.length > 0) {
      let tmp;
      if (filterIsActive === 'active') {
        tmp = consentList.filter(x => x.status !== 'EXPIRED' && x.status !== 'REVOKED')

      } else {
        tmp = consentList.filter(x => x.status === 'EXPIRED' || x.status === 'REVOKED')
      }
      // setup dataholder dropdown
      setDhDropDown(tmp.filter((value, index, self) => {
        const _findIndex = (ele) => ele.holderName === value.holderName
        return self.findIndex(_findIndex) === index
      }))
      // setup scopes dropdown
      setScopesDropDown(_.union(...tmp.map(d => d.scopes)))
    }
  }, [consentList, filterIsActive])

  return (
    <Layout.ColumnLayout title={'Dashboard'}>
      {/* Add logout button instead of consumer */}
      <HeaderBanner hamburger={false} commercial={true} commercialProperties={userData} userIsLoading={userIsLoading} />
      <Vis.MobileOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col" style={{ padding: '0px' }}>
            <Text.H3 style={{ padding: '30px 10px' }}>Feature not available in mobile view.</Text.H3>

          </WhiteSection>
        </WhiteSection>
      </Vis.MobileOnly>
      <Vis.TabletOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 20px 0px 20px' }}>
          <WhiteSection flex="col" style={{ padding: '0px' }}>
            <Flex flex="nogrow">
              <Text.H3>{headingText}</Text.H3>
              <ActionButton onClick={() => navTo.configureyourconsent_2()} style={{ fontWeight: '600', fontSize: '15px', background: '#ffffff', padding: '8px 15px', borderRadius: '9px' }}>Create new consent</ActionButton>
            </Flex>

            <NoticationToaster
              showNotification={openNotification}
              closeNotification={() => setOpenNotification(false)}
              title="Revoke Consent"
              bodyMessage={notificationMessage} />

            {/* Start Filter section */}
            <Flex flex='nogrow' style={{ margin: '2rem 0' }}>
              <Flex flex='w100 vcenter between pad-sm' style={{ borderRadius: '8px', background: '#f8f9fa', gap: '6rem' }}>

                <div>
                  <Flex style={{ background: '#41B6E6', padding: '2px', borderRadius: '6px' }}>
                    <RadioButton type="radio" name="toggle" id="active" value='active' checked={filterIsActive === 'active'} onChange={handleChangeActive} disabled={filterIsActive === 'active'} />
                    <RadioLabel for='active'>Active</RadioLabel>
                    <RadioButton type="radio" name="toggle" id="inactive" value='inactive' checked={filterIsActive === 'inactive'} onChange={handleChangeActive} disabled={filterIsActive === 'inactive'} />
                    <RadioLabel for='inactive'>Inactive</RadioLabel>
                    {/* <button style={{ background: '#fff', color: '#00424D', borderRadius: '4px', fontWeight: '700', padding: '3px 20px' }}>Active</button> */}
                    {/* <button style={{ background: 'transparent', color: '#00424D', borderRadius: '4px', padding: '3px 20px' }}>Inactive</button> */}
                  </Flex>
                </div>

                <FilterTextBox flex=''>
                  <img src={SearchVector} alt="" style={{ paddingLeft: '10px' }} />
                  <input type="text"
                    style={{ width: '100%', background: 'transparent', border: 'none', outline: 'none', paddingLeft: '10px', opacity: '0.6' }}
                    placeholder="Filter by keyword"
                    value={localFilterString}
                    onChange={debounceHandleSearchTextChange} />
                </FilterTextBox>


                <div style={{ width: '250px' }}>
                  <Flex style={{ gap: '5px' }}>
                    {/* Data holder Whisper */}
                    <FilterPopover title="Data holder" filterTitle="Select your data holder" dataHolder={dhDropdown} onSelectFn={setFilterSelectDH} selectedState={filterSelectDH} />

                    {/* Scopes Whisper */}
                    <FilterPopover title='Scopes' filterTitle='Select scopes' scopes={scopesDropDown} onSelectFn={setFilterSelectScopes} selectedState={filterSelectScopes} />
                  </Flex>
                </div>

              </Flex>
            </Flex>
            {/* End Filter section */}

            {/* Notification show after revoke */}
            {openRevokeBanner && <Flex flex='vcenter w100'
              style={{
                height: '60px', maxHeight: '60px',
                background: '#F5FAFF', padding: '15px 20px', borderRadius: '4px'
              }}>
              <Flex flex='vcenter'>
                <img src={ErrorIcon} alt="" />
                <Text.Karbon16 style={{ padding: '5px 0 0 21px', fontWeight: '500' }}>
                  The consent has now been revoked. You will see this is your inactive consents now.
                </Text.Karbon16>
              </Flex>
              <RevokeCloseButton flex='vcenter hend' onClick={() => setOpenRevokeBanner(false)}>
                <Text.Karbon16 style={{ padding: '5px 5px 0 0', fontWeight: '500' }}>Close</Text.Karbon16>
                <button style={{ background: 'transparent' }}>
                  <img src={CloseDarkBlue} alt="" />
                </button>
              </RevokeCloseButton>
            </Flex>
            }

            {/* Table Hover */}
            {/* .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover */}
            <Table
              wordWrap
              loading={isLoading || isSorting}
              height={500}
              autoHeight={true}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              hover={true}
              data={getData()}
              id="table"
              bodyRef={ref => {
                tableBody = ref
              }}>
              <Column sortable flexGrow={1.5} verticalAlign={'middle'}>
                <TableHeader>Data holder consent</TableHeader>
                <DataHoldersCell dataKey='holderName' />
              </Column>
              <Column sortable flexGrow={1} verticalAlign={'middle'}>
                <TableHeader>Expiry({moment.tz(moment.tz.guess()).format('z')})</TableHeader>
                <TableCell dataKey="expiry">
                  {rowData => rowData['expiry'] !== null ? moment(rowData['expiry']).format('DD/MM/YYYY hh:mm a') : '-'}
                </TableCell>
              </Column>
              <Column sortable flexGrow={1.2} verticalAlign={'middle'} align='right' style={{ paddingRight: '1.8rem' }}>
                <TableHeader>Duration (sec.)</TableHeader>
                <TableCell dataKey="duration" />
              </Column>
              <Column flexGrow={1.4} verticalAlign={'middle'}>
                <TableHeader>Consent Scopes</TableHeader>
                <ScopesCell dataKey="scopes" />
              </Column>
              <Column flexGrow={2}>
                <TableHeader>Primary actions</TableHeader>
                <ActionCell dataKey="action" />
              </Column>
            </Table>

            <WhiteSection flex="hend">
              <div className="rs-table-pagination-toolbar" style={{ paddingRight: '0px', height: '36px', lineHeight: '36px' }}>
                {displayConsentList.length > 0 ? ((page) * 8 > displayConsentList.length ? displayConsentList.length : (page) * 8) : 0} of {displayConsentList.length}
              </div>
              <Pagination
                first={false}
                last={false}
                prev
                next
                size='md'
                total={displayConsentList.length}
                limit={limit}
                displayLength={limit}
                activePage={page}
                maxButtons={1}
                onChangePage={setPage}
                showLengthMenu={false}
              />
            </WhiteSection>

          </WhiteSection>
        </WhiteSection>
      </Vis.TabletOnly>
      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col" style={{ padding: '0px' }}>
            <Flex flex="nogrow">
              <Text.H3>{headingText}</Text.H3>
              <ActionButton onClick={() => navTo.configureyourconsent_2()} style={{ fontWeight: '600', fontSize: '15px', background: '#ffffff', padding: '8px 15px', borderRadius: '9px' }}>Create new consent</ActionButton>
            </Flex>

            <NoticationToaster
              showNotification={openNotification}
              closeNotification={() => setOpenNotification(false)}
              title="Revoke Consent"
              bodyMessage={notificationMessage} />

            {/* Start Filter section */}
            <Flex flex='nogrow' style={{ margin: '2rem 0' }}>
              <Flex flex='w100 vcenter between pad-sm' style={{ borderRadius: '8px', background: '#f8f9fa', gap: '6rem' }}>

                <div>
                  <Flex style={{ background: '#41B6E6', padding: '2px', borderRadius: '6px' }}>
                    <RadioButton type="radio" name="toggle" id="active" value='active' checked={filterIsActive === 'active'} onChange={handleChangeActive} disabled={filterIsActive === 'active'}/>
                    <RadioLabel for='active'>Active</RadioLabel>
                    <RadioButton type="radio" name="toggle" id="inactive" value='inactive' checked={filterIsActive === 'inactive'} onChange={handleChangeActive} disabled={filterIsActive === 'inactive'}/>
                    <RadioLabel for='inactive'>Inactive</RadioLabel>
                    {/* <button style={{ background: '#fff', color: '#00424D', borderRadius: '4px', fontWeight: '700', padding: '3px 20px' }}>Active</button> */}
                    {/* <button style={{ background: 'transparent', color: '#00424D', borderRadius: '4px', padding: '3px 20px' }}>Inactive</button> */}
                  </Flex>
                </div>

                <FilterTextBox flex=''>
                  <img src={SearchVector} alt="" style={{ paddingLeft: '10px' }} />
                  <input type="text"
                    style={{ width: '100%', background: 'transparent', border: 'none', outline: 'none', paddingLeft: '10px', opacity: '0.6' }}
                    placeholder="Filter by keyword"
                    value={localFilterString}
                    onChange={debounceHandleSearchTextChange} />
                </FilterTextBox>


                <div style={{ width: '250px' }}>
                  <Flex style={{ gap: '5px' }}>
                    {/* Data holder Whisper */}
                    <FilterPopover title="Data holder" filterTitle="Select your data holder" dataHolder={dhDropdown} onSelectFn={setFilterSelectDH} selectedState={filterSelectDH} />

                    {/* Scopes Whisper */}
                    <FilterPopover title='Scopes' filterTitle='Select scopes' scopes={scopesDropDown} onSelectFn={setFilterSelectScopes} selectedState={filterSelectScopes} />
                  </Flex>
                </div>

              </Flex>
            </Flex>
            {/* End Filter section */}

            {/* Notification show after revoke */}
            {openRevokeBanner && <Flex flex='vcenter w100'
              style={{
                height: '60px', maxHeight: '60px',
                background: '#F5FAFF', padding: '15px 20px', borderRadius: '4px'
              }}>
              <Flex flex='vcenter'>
                <img src={ErrorIcon} alt="" />
                <Text.Karbon16 style={{ padding: '5px 0 0 21px', fontWeight: '500' }}>
                  The consent has now been revoked. You will see this is your inactive consents now.
                </Text.Karbon16>
              </Flex>
              <RevokeCloseButton flex='vcenter hend' onClick={() => setOpenRevokeBanner(false)}>
                <Text.Karbon16 style={{ padding: '5px 5px 0 0', fontWeight: '500' }}>Close</Text.Karbon16>
                <button style={{ background: 'transparent' }}>
                  <img src={CloseDarkBlue} alt="" />
                </button>
              </RevokeCloseButton>
            </Flex>
            }

            {/* Table Hover */}
            {/* .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover */}
            <Table
              wordWrap
              loading={isLoading || isSorting}
              height={500}
              autoHeight={true}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              hover={true}
              data={getData()}
              id="table"
              bodyRef={ref => {
                tableBody = ref
              }}>
              <Column sortable flexGrow={1.5} verticalAlign={'middle'}>
                <TableHeader>Data holder consent</TableHeader>
                <DataHoldersCell dataKey='holderName' />
              </Column>
              <Column sortable flexGrow={1} verticalAlign={'middle'}>
                <TableHeader>Expiry({moment.tz(moment.tz.guess()).format('z')})</TableHeader>
                <TableCell dataKey="expiry">
                  {rowData => rowData['expiry'] !== null ? moment(rowData['expiry']).format('DD/MM/YYYY hh:mm a') : '-'}
                </TableCell>
              </Column>
              <Column sortable flexGrow={1.2} verticalAlign={'middle'} align='right' style={{ paddingRight: '1.8rem' }}>
                <TableHeader>Duration (sec.)</TableHeader>
                <TableCell dataKey="duration" />
              </Column>
              <Column flexGrow={1.4} verticalAlign={'middle'}>
                <TableHeader>Consent Scopes</TableHeader>
                <ScopesCell dataKey="scopes" />
              </Column>
              <Column flexGrow={2}>
                <TableHeader>Primary actions</TableHeader>
                <ActionCell dataKey="action" />
              </Column>
            </Table>

            <WhiteSection flex="hend">
              <div className="rs-table-pagination-toolbar" style={{ paddingRight: '0px', height: '36px', lineHeight: '36px' }}>
                {displayConsentList.length > 0 ? ((page) * 8 > displayConsentList.length ? displayConsentList.length : (page) * 8) : 0} of {displayConsentList.length}
              </div>
              <Pagination
                first={false}
                last={false}
                prev
                next
                size='md'
                total={displayConsentList.length}
                limit={limit}
                displayLength={limit}
                activePage={page}
                maxButtons={1}
                onChangePage={setPage}
                showLengthMenu={false}
              />
            </WhiteSection>

          </WhiteSection>
        </WhiteSection >
      </Vis.DesktopOnly >
      <WhiteSection flex="col nogrow">
        <FooterBanner />
      </WhiteSection>
      <DetailModal isOpen={isModalOpen} FnCloseDetail={() => setIsModalOpen(false)} consentDetail={modalData} />
    </Layout.ColumnLayout >
  )
}

export default Dashboard
